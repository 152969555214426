<template>
  <el-card shadown="always">
    <template #header>
      <el-row type="flex" justify="start"
        ><h1>Lançar pedido para {{ publicSell?.name }}</h1></el-row
      >
    </template>
    <el-descriptions border direction="vertical" column="1">
      <el-descriptions-item :min-width="250">
        <template #label>Licitação</template>
        <el-select
          v-model="publicSell"
          value-key="uid"
          :disabled="!!publicSell?.uid"
          @change="fetchPublicSell(publicSell.uid)"
          @visible-change="(v) => (v ? fetchPublicSells() : null)"
        >
          <el-option
            v-for="pSell in publicSells"
            :key="pSell.uid"
            :label="pSell.name"
            :value="pSell"
          ></el-option>
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Empresa</template>
        {{ publicSell?.firm?.name }}
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Cliente</template>
        {{ publicSell?.partner?.name }}
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Endereço</template>
        {{ publicSell?.firm?.name }}
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Data Início</template>
        {{ dateFormatter.format(new Date(publicSell?.start_at)) }}
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Data fim</template>
        {{ dateFormatter.format(new Date(publicSell?.end_at)) }}
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Selecionar empenho</template>
        <el-select v-model="resourceReservation" value-key="uid">
          <el-option
            v-for="reserv in PublicSellReservations"
            :key="reserv.uid"
            :label="reserv.name"
            :value="reserv"
            :disabled="reserv.status !== 'active'"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item :min-width="250">
        <template #label>Data da entrega</template>
        <base-date-picker v-model="deliver_at"></base-date-picker>
      </el-descriptions-item>
      <el-descriptions-item :min-width="250">
        <template #label>Endereço de Entrega</template>
        <base-input v-model="deliver_in"></base-input>
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      :cell-style="() => 'text-align:center;'"
      :data="PublicSellProducts"
      style="width: 100%; z-index: 0"
    >
      <el-table-column
        label="nome"
        :min-width="250"
        prop="related.product.name"
      ></el-table-column>

      <el-table-column
        label="valor"
        :min-width="250"
        :formatter="
          ({ related }) => currencyFormatter.format(Number(related.amount))
        "
      ></el-table-column>

      <el-table-column
        label="quantidade"
        :min-width="250"
        :formatter="
          ({ max_weight }) => weightFormatter.format(Number(max_weight))
        "
      ></el-table-column>

      <el-table-column
        label="vendido"
        :min-width="250"
        :formatter="(row) => weightFormatter.format(calcTotalSold(resourceReservation, row)) + ' .kg'"
      ></el-table-column>

      <el-table-column
        label="disponível"
        :min-width="250"
        :formatter="
          (product) => weightFormatter.format(getResourceReservationProductRemaining(product)) + ' kg.'
        "
      ></el-table-column>

      <el-table-column label="obs" prop="related.obs"></el-table-column>

      <el-table-column label="venda">
        <template #default="{ row: resourceReservationProduct }">
          <base-input
            type="money"
            :minimumFractionDigits="3"
            :max="getResourceReservationProductRemaining(resourceReservationProduct)"
            :disabled="getResourceReservationProductRemaining(resourceReservationProduct) <= 0"
            v-model="resourceReservationProduct.quantity"
          ></base-input
        ></template>
      </el-table-column>
    </el-table>

    <el-row type="flex" justify="end" class="mt-1">
      <base-button
        :onClick="clearOrderForm"
        type="warning"
        text="Limpar"
      ></base-button>
      <base-button
        :onClick="createOrder"
        type="primary"
        text="Criar pedido"
      ></base-button>
    </el-row>
  </el-card>
</template>
<script>
import PublicSellService from "../services/public_sells";
import OrderService from "../services/orders";
import {
  dateFormatter,
  currencyFormatter,
  weightFormatter,
} from "../utils/formatters";
import { addDays } from "../utils/dates";
import { notifyError, notifySuccess } from "../utils/notifiers";
export default {
  name: "PreOrderPublicSell",
  data() {
    return {
      publicSell: {},
      publicSells: [],
      resourceReservation: {},
      deliver_at: null,
      deliver_in: null,
      dateFormatter,
      currencyFormatter,
      weightFormatter,
    };
  },
  computed: {
    PublicSellId() {
      return this.$route.params.publicSellId;
    },
    PublicSellProducts() {
      return this.resourceReservation?.quantities || [];
    },
    PublicSellReservations() {
      return this.publicSell?.reservations || [];
    },
  },
  mounted() {
    if (this.PublicSellId) this.fetchPublicSell(this.PublicSellId);
    else this.fetchPublicSells();
  },
  methods: {
    calcTotalSold(resourceReservation, product) {
      if (!resourceReservation?.orders?.length) return 0;

      const productMountedId = product?.related?.mounted?.id || product?.mounted?.id;

      return resourceReservation
        .orders
        .map(({ products }) => products)
        .flat()
        .filter(product => product.mounted.id === productMountedId)
        .reduce((total, product) => total + Number(product.weight), 0);
    },
    getResourceReservationProductRemaining(product){
      return Number(product.max_weight) - this.calcTotalSold(this.resourceReservation, product);
    },
    clearOrderForm() {
      this.publicSell = {};
      this.resourceReservationId = {};
    },
    async fetchPublicSell(publicSellId) {
      const { publicSell } = await PublicSellService(publicSellId).get();

      if (publicSell) {
        this.publicSell = publicSell;
        this.publicSells = [publicSell];
      }
    },
    async fetchPublicSells() {
      const { publicSells } = await PublicSellService().index();

      if (publicSells) this.publicSells = publicSells;
    },
    byProductOrder(p) {
      return {
        productUid: p.related?.product?.uid,
        uid: p.related?.mounted?.id,
        storage: p.related.storage,
        ncm:
          p.related.storage === "cold"
            ? p.related.mounted.codes.ncm_frozen
            : p.related.mounted.codes.ncm_chilled,
        gtim: p.related.mounted.codes.gtim,
        ean: p.related.mounted.codes.ean,
        qrcode: p.related.mounted.codes.qrcode,
        cest: p.related.mounted.codes.cest,
        obs: p.related.obs,
        quantity: p.quantity,
        un_quantity: p.related.product.un,
        weight: p.quantity,
        amount: p.related.amount,
        total_amount: Number(p.related.amount) * Number(p.quantity),
      };
    },
    async createPayment(order, payment) {
      await OrderService(order.uid).Payments().create(payment);
    },
    async createOrder() {
      const { order } = await OrderService().create({
        public_sell_id: this.publicSell.uid,
        resource_reservation_id: this.resourceReservation.uid,
        client_id: this.publicSell.partner.uid,
        deliver_at: this.deliver_at || addDays(new Date(), 1),
        payment_method: "cash",
        deliver_in: this.deliver_in,
        seller_id: this.publicSell?.partner?.seller?.uid,
        //payment_days: this.publicSell.partner?.payment_days,
        products: this.PublicSellProducts.map(this.byProductOrder),
      });

      if (order) {
        await this.createPayment(order, {
          amount: 0,
          obs: "Pagamento criado automaticamente",
          payment_method: "cash",
          expires_at: addDays(new Date(), 30),
        });
        notifySuccess("Pedido criado com sucesso");
        this.clearOrderForm();
      } else
        notifyError(
          "Erro ao criar pedido, verifique os campos e tente novamente"
        );
    },
  },
};
</script>
<style scoped>
.mt-1 {
  margin-top: 8px;
}
</style>
